.myToast {
  visibility: hidden;
  min-width: 250px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: var(--bs-secondary);
  color: #fff;
  text-align: center;
  border-radius: 7px;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  font-size: 14px;
}

.myToast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -100px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -100px;
    opacity: 0;
  }
}
