/* SIDE BAR */

.side-bar {
  height: 80vh;
  position: sticky;
  top: 6rem;
  overflow-y: scroll;
}

/* Hide the scrollbar by default */
.side-bar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f5f5f5;
  visibility: hidden;
}

.side-bar::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 5px;
  visibility: hidden;
}

/* Show the scrollbar on hover */
.side-bar:hover::-webkit-scrollbar {
  visibility: visible;
}

.side-bar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*  */

@media (max-width: 1000px) {
  .side-bar {
    height: 80dvh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}
