.above-nav {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  font-size: 13px;
}

.contact,
.social {
  display: flex;
  flex-wrap: wrap;
}

.above-nav a {
  color: var(--bs-white);
}

.contact div {
  margin-right: 15px;
}

.contact div svg {
  margin-right: 5px;
}

.social svg {
  font-size: 19px;
  margin-left: 10px;
}
