nav {
  background-color: var(--bs-white);
  min-height: 4rem;
}

.navbar-brand img {
  width: 35px;
  margin-right: 5px;
}

.navbar-toggler span {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}
