footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: auto;

  background-color: var(--bs-secondary-dark);
  color: var(--bs-white);
}

footer a {
  text-decoration: none;
  color: unset;
}

footer a:hover {
  color: aqua;
}
